import { gql } from '@apollo/client';

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct(
    $brandName: String!
    $molecules: String
    $description: String
    $packSize: String!
    $supplierName: String!
    $manufacturer: String!
    $logo: String
    $categoryId: String
    $therapeuticType: String
    $reorderPoint: Int
    $reorderMax: Int
    $reorderUnit: String
    $barcode: String
    $unitOfMeasurement: ProductUOMEnum
    $status: String
    $supplierCost: Float
    $marketRrp: Float
    $fixedItemPrice: Float
    $fixedSellingPrice: Float
    $promoPrice: Float
    $automaticMarketplacePrice: Boolean
    $automaticErpPrice: Boolean
    $safetyStock: Int
    $maxLeadTime: Int
    $minimumOrderQuantity: Int
    $productVariant: String
    $productClass: String
    $orderCost: Float
    $buffer: Int
    $syncStores: [Int]
    $noOfUom:Int!
  ) {
    createProduct(
      brandName: $brandName
      molecules: $molecules
      description: $description
      packSize: $packSize
      supplierName: $supplierName
      manufacturer: $manufacturer
      supplierCost: $supplierCost
      marketRrp: $marketRrp
      fixedItemPrice: $fixedItemPrice
      fixedSellingPrice: $fixedSellingPrice
      promoPrice: $promoPrice
      automaticMarketplacePrice: $automaticMarketplacePrice
      automaticErpPrice: $automaticErpPrice
      imageLink: $logo
      categoryId: $categoryId
      therapeuticType: $therapeuticType
      barcode: $barcode
      unitOfMeasurement: $unitOfMeasurement
      status: $status
      reorderPoint: $reorderPoint
      reorderMax: $reorderMax
      reorderUnit: $reorderUnit
      safetyStock: $safetyStock
      maxLeadTime: $maxLeadTime
      minimumOrderQuantity: $minimumOrderQuantity
      productVariant: $productVariant
      productClass: $productClass
      orderCost: $orderCost
      buffer: $buffer
      syncStores: $syncStores
      noOfUom: $noOfUom
      ){
      message
      product {
        id
        status
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct(
    $productId: Int!
    $brandName: String!
    $molecules: String
    $description: String
    $packSize: String!
    $supplierName: String!
    $manufacturer: String!
    $logo: String
    $categoryId: String
    $therapeuticType: String
    $barcode: String
    $unitOfMeasurement: ProductUOMEnum
    $supplierCost: Float
    $marketRrp: Float
    $fixedItemPrice: Float
    $fixedSellingPrice: Float
    $promoPrice: Float
    $automaticMarketplacePrice: Boolean
    $reorderQuantity: Int
    $reorderPoint: Int
    $reorderMin: Float
    $reorderMax: Float
    $reorderUnit: String
    $safetyStock: Int
    $maxLeadTime: Int
    $minimumOrderQuantity: Int
    $productVariant: String
    $automaticErpPrice: Boolean
    $productClass: String
    $buffer: Int
    $syncStores: [Int]
    $noOfUom:Int!
  ) {
    updateProduct(
      productId: $productId
      brandName: $brandName
      molecules: $molecules
      description: $description
      packSize: $packSize
      supplierName: $supplierName
      manufacturer: $manufacturer
      imageLink: $logo
      categoryId: $categoryId
      therapeuticType: $therapeuticType
      barcode: $barcode
      unitOfMeasurement: $unitOfMeasurement
      supplierCost: $supplierCost
      marketRrp: $marketRrp
      fixedItemPrice: $fixedItemPrice
      fixedSellingPrice: $fixedSellingPrice
      promoPrice: $promoPrice
      automaticMarketplacePrice: $automaticMarketplacePrice
      automaticErpPrice: $automaticErpPrice
      reorderQuantity: $reorderQuantity
      reorderPoint: $reorderPoint
      reorderMin: $reorderMin
      reorderMax: $reorderMax
      reorderUnit: $reorderUnit
      safetyStock: $safetyStock
      maxLeadTime: $maxLeadTime
      minimumOrderQuantity: $minimumOrderQuantity
      productVariant: $productVariant
      productClass: $productClass
      buffer: $buffer
      syncStores: $syncStores
      noOfUom: $noOfUom
    ){
      message
      product {
        id
        status
      }
    }
  }
`;

export const UPDATE_PRODUCTS_STATUS_MUTATION = gql`
  mutation updateProductStatus(
    $productId: String!
    $status: String!
  ) {
    updateProductStatus(
      productId: $productId
      status: $status
    ){
      message
    }
  }
`;

export const CREATE_BATCH_MUTATION = gql`
  mutation createBatch(
    $batchNo: String
    $dateRecieved: String
    $expiryDate: String
    $productId: String
    $quantity: Float
    $supplierId: String
    $unitCost: Int
    $stockReportId: Int
  ) {
    createBatch(
      batchNo: $batchNo
      dateReceived: $dateRecieved
      expiryDate: $expiryDate
      productId: $productId
      quantity: $quantity
      supplierId: $supplierId
      unitCost: $unitCost
      stockReportId: $stockReportId
    ){
      message
      batch {
        id
      }
    }
  }
`;

export const UPDATE_BATCH_MUTATION = gql`
  mutation updateBatch(
    $batchId: String
    $expiryDate: String
    $quantity: Float
    $unitCost: Int
  ) {
    updateBatch(
      batchId: $batchId
      expiryDate: $expiryDate
      quantity: $quantity
      unitCost: $unitCost
    ){
      message
      batch {
        id
      }
    }
  }
`;
export const DELETE_BATCH_MUTATION = gql`
  mutation deleteBatch($batchId: String!) {
    deleteBatch( batchId: $batchId ){
      message
    }
  }
`;
export const DELETE_PRODUCT_VERSION_MUTATION = gql`
  mutation deleteProductVersion($productId: Int!) {
    deleteProductVersion( productId: $productId ){
      message
    }
  }
`;

export const MAKE_ONLY_SUPPLY_CHAIN_PRODUCT_VISIBLE = gql`
  mutation makeOnlySupplyChainProductVisible{
    makeOnlySupplyChainProductVisible{
      message
    }
  }
`;

export const UPDATE_PRODUCT_BATCH_STATUS = gql`
  mutation updateProductBatchStatus(
    $batchId: String!, $status: String!
    ) {
    updateProductBatchStatus(
      batchId: $batchId, status: $status
    ) {
      message
    }
  }
`;

export const DELETE_PRODUCTS = gql`
  mutation batchDeleteProducts($productIds: [Int]!){
    batchDeleteProducts(productIds: $productIds){
      message
    }
  }
`;
