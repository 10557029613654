import {
  Grid, Typography, Paper, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 1rem 0 1.5rem;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsPaper = styled(Paper)`
  margin-top: 1rem;
  padding: 1.5rem 3rem;
  border-radius: 1rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const TCustomButton = styled(Button)`
  font-weight: 700;
  font-size: 0.75rem;
  width: 10rem;
  height: 48px;
  border: 1px solid #235A91;
  filter: drop-shadow(0px 4px 14px rgba(151, 151, 151, 0.19));
  border-radius: 5px;
  color: #235A91;
  marginRight: .8rem;
`;

export const ButtonDarkCustomButton = styled(Button)`
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 0.75rem;

  width: ${(props) => (props.addProduct ? '8.25rem' : '14.188rem')};
  height: 48px;

  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);

  &:hover {
    background: #235A91;
    color: #ffffff;
  }
`;

export const OutOfStockButton = styled(Button)`
  margin-right: .8rem;
`;
