import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  NavbarContainer, DateTimeTypo, NavLeftMenu, NavRightMenu, AffiliateImage, AffiliatesLogo, NavItem, ProfileTypo,
  DrawerContainer, SideNavItems, SideNavHeader, HeaderIcon, HeaderMenuButton, BellButton, PharmIQLogo, PharmIQLogoWhite,
  LogoIcon, ViewCartTypo, DropDownArrow, CloseMenuButton, SideProfileTypo, SideLogoIcon, SideAffiliateImage, MeunIcon,
  AdminDateTimeTypo
} from './navigationContainer.styles';
import { ERP_NOTIFICATIONS } from '../../queries/notifications';
import { GET_LIVE_DAILY_STOCK_TRANSFERS } from '../../queries/stockTransfer';
import { CREATE_ORDER_MUTATION } from '../../mutations/orders';
import { LOGOUT_MUTATION } from '../../mutations/auth';
import { OFFLINE_SYNC } from '../../mutations/business';
import { Subscriber } from '../../utils/subscriber';
import NotificationsActionTypes from '../../providers/reducers/notifications/notificationsTypes';
import InfoTillDialogue from '../categories/infoTillDialog';
import { getUser } from '../../utils/utils';
import AffiliatesImage from '../../assets/images/navigation/Affiliate_Placeholder.png';
import SimpleBadge from '../shared/simpleBadge';
import AffiliatesPopper from './affiliatesPopper';
import TopNavigationPopperMobile from './topNavigationPopperMobile';
import NotificationsPopper from './notificationsPopper';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import RemoveDataDialog from './removeDataDialog';
import { deleteDb } from '../../lib/db';
import {
  MenuToggle, MenuToggleWhite, BoxStore, Timer, CartIco, BackOrderIcon, BellIco, Dashboard, Returns, Users, OrdersTruck,
  Sales, BatchDetails, ScReport, StTransfer, ProductType, Categories, Orders, Invoice, ReportsIcon, UploadIcon,
  Inventory, MpHome, MgAffiliate, Clock, Dollar, TrackOrderIcon, MangeOrderIcon, Sync, PromoIcon, MenuToggleBgWhite, TopMenuToggle
} from '../../assets/svgs';
import { useStateValue } from '../../providers/stateProvider';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import SideNavItem from './sideNavItem';

export const NavigationContainer = () => {
  const [dateState, setDateState] = useState(new Date());
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [manualOrder, setManualOrder] = useState(false);
  const [deleteDataOpen, setDeleteDataOpen] = useState(false);
  const [affiliatesLogoEl, setAffiliatesLogoEl] = useState(null);
  const [notificationsEl, setNotificationsEl] = useState(null);
  const [openTillDialogue, setOpenTillDialogue] = useState(false);
  const [openNavDialogue, setOpenNavDialogue] = useState(false);
  const [stockTransfers, setStockTransfers] = useState(0);
  const raspEnv = process.env.RASPBERRY_ENV;

  const navigate = useNavigate();

  const [{
    user,
    cart: { cartCounter },
    affiliate: { isBusinessVirtualStore },
    notifications: { count }
  }, dispatch] = Object.values(useStateValue());

  const {
    session, promos, priceFactor, businessName, businessUserId, allowedPermissionsMap: { userPermissions },
  } = user;

  const loggedInUser = getUser(session);
  const CloseShift = 'Close Shift';
  const SyncOffline = 'Sync';
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  const [offlineSync] = useMutation(OFFLINE_SYNC);

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  const handleOfflineSync = () => toast.promise(
    async () => {
      await offlineSync();
    },
    {
      loading: 'Syncing...',
      success: 'Sync successful',
      error: 'Sync failed',
    }
  );

  const updateCartTotal = (currentOrder) => {
    const { supplierorderSet } = currentOrder?.order || {};

    dispatch({
      type: CartActionTypes.CART_COUNTER,
      payload: supplierorderSet
    });
  };

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const location = useLocation();
  useEffect(() => {
    const usablePromos = [];
    const { availablePromos, affiliatePromos } = promos;

    const searchableAvailablePromos = `**${availablePromos?.join('**')}**`;
    affiliatePromos?.forEach((eachPromo) => {
      if (searchableAvailablePromos.indexOf(`**${eachPromo}**`) > -1) {
        usablePromos.push(eachPromo);
      }
    });

    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliatePromos,
        availablePromos,
        usablePromos,
        pricePolicyFactor: priceFactor
      }
    });
  }, []);

  useEffect(() => {
    const { pathname: pname } = location;
    const role = localStorage.getItem('oga_user_role');
    if (role === 'store-manager' || role === 'chain-manager') {
      setManualOrder(true);
      createOrder({
        variables: {
          isManualOrder: true
        }
      });
    } else if (pname !== '/') {
      setManualOrder(false);
      createOrder();
    }
  }, [location, createOrder]);

  const { businessuserSet = [] } = session;
  const { logo } = businessuserSet.length && businessuserSet[0].business;
  const affiliateLogo = logo || AffiliatesImage;

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
    dispatch({ type: 'openSideDrawer' });
  };

  const role = localStorage.getItem('oga_user_role');

  const returnRoute = (name) => {
    switch (name) {
      case 'oga-pharmacy-admin':
        return '/orders-admin';
      case 'affiliate-admin':
        return '/orders';
      case 'store-manager':
        return '/sales';
      case 'supplier-admin':
        return '/orders-supplier';
      default:
        return null;
    }
  };

  const handleSideClick = (route) => {
    toggleSideNav();
    setTimeout(() => {
      if (route === 'orders' && !isBusinessVirtualStore) {
        navigate(returnRoute(role));
        return;
      }
      navigate(`/${route}`);
    }, 500);
  };

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    })
      .then((resp) => {
        const { status, message } = resp.data.logoutUser;
        if (status) {
          toast.success(message);
          setTimeout(() => navigate('/', { replace: true }), 1500);
          setTimeout(() => {
            document.location = '';
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const deleteLocalData = () => deleteDb()
    .then(() => {
      toast.success('Database successfully deleted');
    })
    .catch((err) => {
      toast.error('Could not delete database', err?.message);
    })
    .finally(() => {
      handleLogout();
    });

  const handlMobileMenuClick = (event) => {
    setOpenNavDialogue(openNavDialogue ? null : event.currentTarget);
  };
  const handleAffiliateLogoClick = (event) => {
    setAffiliatesLogoEl(affiliatesLogoEl ? null : event.currentTarget);
  };
  const handleBellClick = (event) => {
    setNotificationsEl(notificationsEl ? null : event.currentTarget);
  };

  const OGA_PHARMACY_ADMIN_SIDE_ITEMS = [
    ['dashboard', MpHome, 'dashboard'],
    ['orders-admin', Users, 'Manage Orders', 'manage_orders_view_manage_orders'],
    ['data-analysis', Sales, 'Data Analysis', 'data_analysis_view_data_analysis'],
    ['manage-affiliates', MgAffiliate, 'Manage Affiliates', 'manage_affiliates_view_manage_affiliates'],
    ['suppliers', Clock, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['payments', Dollar, 'Payments', 'payment_view_payment'],
    ['therapeutic-types', ProductType, 'Product types', 'product_type_view_product_type'],
    ['downloads', ScReport, 'Downloads'],
  ];
  const STORE_MANAGER_SIDE_ITEMS = [
    ['affiliate-dashboard', Dashboard, 'dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['categories', Categories, 'Pricing categories', 'pricing_categories_view_pricing_categories'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    // ['invoices', Invoice, 'invoices'],
    ['stock-transfer', StTransfer, 'stock transfer', 'stock_transfer_view_stock_transfer'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['reports', ReportsIcon, 'Reports'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],

    // ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    // ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['downloads', ScReport, 'Downloads'],
    ['promotions', PromoIcon, 'Promotions', 'promotions_view_promotions'],
    ['uploads', UploadIcon, 'Uploads']
  ];
  const CHAIN_MANAGER_SIDE_ITEMS = [
    ['affiliate-dashboard', Dashboard, 'dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    // ['invoices', Invoice, 'invoices'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    ['stock-transfer', StTransfer, 'stock transfer', 'stock_transfer_view_stock_transfer'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['reports', ReportsIcon, 'Reports'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
    ['downloads', ScReport, 'Downloads'],
    ['promotions', PromoIcon, 'Promotions', 'promotions_view_promotions'],
    ['uploads', UploadIcon, 'Uploads']
  ];
  const VIRTUAL_STORE_SIDE_ITEMS = [
    ['orders', Orders, 'orders'],
    ['orders-admin', Orders, 'order processing'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
  ];
  const SUPPLIER_SIDE_ITEMS = [
    ['products', BatchDetails, 'products'],
    ['orders', Orders, 'orders']
  ];
  const AFFILIATE_SIDE_ITEMS = [
    ['orders', Orders, 'orders'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
  ];
  const AFFILIATE_ADMIN_SIDE_ITEMS = [
    ['orders', MangeOrderIcon, 'Manage Orders'],
    ['track-order', TrackOrderIcon, 'Track Order']
  ];

  const STORE_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['categories', Categories, 'Pricing categories', 'pricing_categories_view_pricing_categories'],
    ['affiliate-dashboard', Dashboard, 'dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    // ['invoices', Invoice, 'invoices'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['downloads', ScReport, 'Downloads'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
  ];
  const CHAIN_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['affiliate-dashboard', Dashboard, 'dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    // ['invoices', Invoice, 'invoices'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
    ['downloads', ScReport, 'Downloads']
  ];

  const ADMIN = [
    ['admin/preloaded-inventory', Inventory, 'Preloaded Inventory'],
    ['admin/business', Users, 'Business'],
    ['downloads', ScReport, 'Downloads'],
    ['uploads', UploadIcon, 'Uploads']
  ];

  const sideItems = () => {
    if (isBusinessVirtualStore) {
      return VIRTUAL_STORE_SIDE_ITEMS;
    }
    if (raspEnv && role === 'store-manager') {
      return STORE_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    if (raspEnv && role === 'chain-manager') {
      return CHAIN_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    switch (role) {
      case 'oga-pharmacy-admin':
        return OGA_PHARMACY_ADMIN_SIDE_ITEMS;
      case 'supplier-admin':
        return SUPPLIER_SIDE_ITEMS;
      case 'store-manager':
        return STORE_MANAGER_SIDE_ITEMS;
      case 'chain-manager':
        return CHAIN_MANAGER_SIDE_ITEMS;
      case 'affiliate-admin':
        return AFFILIATE_ADMIN_SIDE_ITEMS;
      case 'admin':
        return ADMIN;
      default:
        return AFFILIATE_SIDE_ITEMS;
    }
  };

  const { data: notificationsData, refetch } = useQuery(ERP_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    variables: {
      pageCount: 10,
      pageNumber: 1
    }
  });
  const { erpNotifications, erpNotificationsTotalNumber } = notificationsData ?? [];
  useEffect(() => {
    Subscriber.report('NotificationCount', erpNotificationsTotalNumber);
    dispatch({
      type: NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT,
      payload: {
        count: erpNotificationsTotalNumber,
      }
    });
  }, [erpNotifications, erpNotificationsTotalNumber]);
  console.log(count, 'cartCounter')

  const { data: stockTransferData } = useQuery(GET_LIVE_DAILY_STOCK_TRANSFERS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    variables: {
      status: 'RECEIVED',
      dateFrom: moment().startOf('day'),
      dateTo: moment().endOf('day')
    }
  });

  useEffect(() => {
    if (stockTransferData && stockTransferData.stockTransfers) {
      const pending = stockTransferData.stockTransfers.filter((x) => x.status === 'PENDING');
      setStockTransfers(pending.length);
    }
  }, [stockTransferData]);

  const admin = role === 'admin';

  return (
    <>
      <NavbarContainer theme={useTheme()} open={sideNavOpen}>
        <Grid item container md={4} xs={8} alignItems="center" justifyContent="spaceBetween">
          <NavLeftMenu item container>
            <HeaderMenuButton className="navigation-hamburger" onClick={toggleSideNav}>
              <MenuToggle style={{ fontSize: isSmallScreen ? '6rem' : '2.05rem' }} />
            </HeaderMenuButton>
            <Hidden mdDown>
              <PharmIQLogo onClick={() => navigate(returnRoute(role))} />
            </Hidden>
          </NavLeftMenu>
          <DateTimeTypo>
            {role !== 'admin'
              && dateState.toLocaleString('en-US', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })}
          </DateTimeTypo>
        </Grid>
        <NavRightMenu item container md={8} xs={6}>
          {/* Display date and time for admin */}
          {admin && (
          <AdminDateTimeTypo>
            {dateState.toLocaleString('en-US', {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })}
          </AdminDateTimeTypo>
          )}

          {/* Display specific items for non-admin roles */}
          {role !== 'admin' && (
          <Hidden lgDown>
            {/* Open Till Dialogue */}
            <NavItem item className="joy-cart-link">
              <HeaderIcon onClick={() => setOpenTillDialogue(true)}>
                <BoxStore style={{ fontSize: '2.05rem' }} />
                <ViewCartTypo>{businessName}</ViewCartTypo>
              </HeaderIcon>
            </NavItem>

            {/* Handle Offline Sync */}
            {raspEnv && (
            <NavItem item className="joy-cart-link">
              <HeaderIcon onClick={handleOfflineSync}>
                <Sync style={{ fontSize: '2.05rem' }} />
                <ViewCartTypo>{SyncOffline}</ViewCartTypo>
              </HeaderIcon>
            </NavItem>
            )}

            {/* Close Shift */}
            <NavItem item className="joy-cart-link">
              <HeaderIcon onClick={() => setOpenTillDialogue(true)}>
                <Timer style={{ fontSize: '2.05rem' }} />
                <ViewCartTypo>{CloseShift}</ViewCartTypo>
              </HeaderIcon>
            </NavItem>

            {/* Navigate to Cart or Manual Cart */}
            {/* <NavItem item className="joy-cart-link">
              <HeaderIcon
                onClick={() => navigate(manualOrder ? '/manual-cart' : '/cart')}
                className="navigation-view-cart"
              >
                <SimpleBadge value={cartCounter}>
                  <CartIco style={{ fontSize: isSmallScreen ? '6rem' : '2.05rem' }} />
                </SimpleBadge>
                <Hidden lgDown>
                  <ViewCartTypo>View Cart</ViewCartTypo>
                </Hidden>
              </HeaderIcon>
            </NavItem> */}
          </Hidden>
          )}

          {/* Affiliates Logo and Icons */}
          <AffiliatesLogo item container>
            {/* Bell Icon with Badge */}
            <Hidden lgDown>
              <BellButton onClick={handleBellClick}>
                <SimpleBadge value={count}>
                  <BellIco style={{ fontSize: isSmallScreen ? '6rem' : '2.05rem' }} />
                </SimpleBadge>
              </BellButton>
            </Hidden>

            {/* Menu Toggle Icon */}
            <MeunIcon onClick={handlMobileMenuClick}>
              <TopMenuToggle style={{ fontSize: isSmallScreen ? '7rem' : '2.05rem' }} />
            </MeunIcon>

            {/* Affiliates Logo */}
            <LogoIcon onClick={handleAffiliateLogoClick}>
              <AffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <Hidden lgDown>
                <ProfileTypo>{loggedInUser}</ProfileTypo>
                <DropDownArrow color="primary" />
              </Hidden>
            </LogoIcon>
          </AffiliatesLogo>
        </NavRightMenu>
      </NavbarContainer>

      <DrawerContainer variant="persistent" anchor="left" open={sideNavOpen}>
        <SideNavHeader item container>
          <Hidden lgDown>
            <HeaderMenuButton className="navigation-hamburger" open={sideNavOpen} onClick={toggleSideNav}>
              <MenuToggleWhite style={{ fontSize: '2.05rem' }} />
            </HeaderMenuButton>
            <PharmIQLogoWhite onClick={() => navigate(returnRoute(role))} />
          </Hidden>
          <Hidden mdUp>
            <CloseMenuButton container>
              <HeaderMenuButton className="navigation-hamburger" onClick={toggleSideNav}>
                <MenuToggleBgWhite style={{ fontSize: '7rem' }} />
              </HeaderMenuButton>
              <PharmIQLogoWhite
                onClick={() => navigate(returnRoute(role))}
                style={{
                  width: '30rem',
                  height: '9rem',
                  marginLeft: '4rem'
                }}
              />
            </CloseMenuButton>

            <SideLogoIcon container>
              <SideAffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <SideProfileTypo>{loggedInUser}</SideProfileTypo>
            </SideLogoIcon>
          </Hidden>
        </SideNavHeader>
        <SideNavItems>
          {sideItems().map((sideItem) => (
            sideItem.length < 4 ? (
              <SideNavItem sideItem={sideItem} key={sideItem.route} handleSideClick={handleSideClick} stockTransfers={stockTransfers} />
            ) : userPermissions?.includes(sideItem[3]) && (
            <SideNavItem sideItem={sideItem} handleSideClick={handleSideClick} stockTransfers={stockTransfers} />
            )
          ))}
        </SideNavItems>
      </DrawerContainer>

      <AffiliatesPopper
        affiliatesLogoEl={affiliatesLogoEl}
        handleAffiliateLogoClick={handleAffiliateLogoClick}
      />
      <TopNavigationPopperMobile
        businessName={businessName}
        CloseShift={CloseShift}
        notificationsEl={notificationsEl}
        handleBellClick={() => setNotificationsEl(true)}
        openNavDialogue={openNavDialogue}
        handlMobileMenuClick={handlMobileMenuClick}
        dialogOpen={openTillDialogue}
        closeDialog={() => setOpenTillDialogue(true)}
        erpNotificationsTotalNumber={count}
      />
      <NotificationsPopper
        notifications={erpNotifications}
        notificationsEl={notificationsEl}
        handleBellClick={handleBellClick}
        businessUserId={businessUserId}
        refetch={refetch}
      />
      <RemoveDataDialog
        open={deleteDataOpen}
        setOpen={setDeleteDataOpen}
        deleteLocalData={deleteLocalData}
      />

      <InfoTillDialogue
        dialogOpen={openTillDialogue}
        closeDialog={() => setOpenTillDialogue(false)}
      />
    </>
  );
};

export default NavigationContainer;
